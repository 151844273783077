import _hslToRgbForReals from "hsl-to-rgb-for-reals";
var exports = {};
// In our case, there's only one dependency
var toRgb = _hslToRgbForReals; // Typically all dependencies should be declared at the top of the file.
// Now let's define an API for our module, we're taking hue, saturation and luminosity values and outputting a CSS compatible hex string.
// Hue is in degrees, between 0 and 359. Since degrees a cyclical in nature, we'll support numbers greater than 359 or less than 0 by "spinning" them around until they fall within the 0 to 359 range.
// Saturation and luminosity are both percentages, we'll represent these percentages with whole numbers between 0 and 100. For these numbers we'll need to enforce a maximum and a minimum, anything below 0 will become 0, anything above 100 will become 100.
// Let's write some utility functions to handle this logic:

function max(val, n) {
  return val > n ? n : val;
}

function min(val, n) {
  return val < n ? n : val;
}

function cycle(val) {
  // for safety:
  val = max(val, 10000000);
  val = min(val, -10000000); // cycle value:

  while (val < 0) {
    val += 360;
  }

  while (val > 359) {
    val -= 360;
  }

  return val;
} // Now for the main piece, the `hsl` function:


function hsl(hue, saturation, luminosity) {
  // resolve degrees to 0 - 359 range
  hue = cycle(hue); // enforce constraints

  saturation = min(max(saturation, 100), 0);
  luminosity = min(max(luminosity, 100), 0); // convert to 0 to 1 range used by hsl-to-rgb-for-reals

  saturation /= 100;
  luminosity /= 100; // let hsl-to-rgb-for-reals do the hard work

  var rgb = toRgb(hue, saturation, luminosity); // convert each value in the returned RGB array
  // to a 2 character hex value, join the array into
  // a string, prefixed with a hash

  return "#" + rgb.map(function (n) {
    return (256 + n).toString(16).substr(-2);
  }).join("");
} // In order to make our code into a bona fide module we have to export it:


exports = hsl;
export default exports;